import React from "react";
import {Link } from "gatsby";
import { platform } from "os";

export default () => (
    <div>
        <h1>About Nambee</h1>
        <p>This is the about page.</p>
        <Link to="/">Home</Link>
    </div>
)
